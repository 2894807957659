import React, { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from "react"
import { message } from "../messages"

export enum FeedbackType {
    ERROR = "ERROR",
    SUCCESS = "SUCCESS"
}

export interface Feedback {
    type: FeedbackType
    message?: string
    showLoginPromptOnInvalidCredentials?: boolean
}

interface DefaultValueProps {
    menuOpen: boolean
    isOffline: boolean
    setIsOffline: Dispatch<SetStateAction<boolean>>
    feedback?: Feedback
    setFeedback: (feedback: Feedback | undefined) => void
    setMenuOpen: Dispatch<SetStateAction<boolean>>
    toggleMenu: () => void
    onNavigate: () => void
}

const defaultValue: DefaultValueProps = {
    menuOpen: false,
    isOffline: !navigator.onLine,
    setIsOffline: () => {},
    feedback: undefined,
    setFeedback: () => {},
    setMenuOpen: () => {},
    toggleMenu: () => {},
    onNavigate: () => {}
}

export const AppContext = React.createContext(defaultValue)

export const AppProvider: FunctionComponent = ({ children }) => {
    const [menuOpen, setMenuOpen] = useState<boolean>(defaultValue.menuOpen)
    const [isOffline, setIsOffline] = useState<boolean>(defaultValue.isOffline)
    const [feedback, setFeedback] = useState<Feedback | undefined>(defaultValue.feedback)

    useEffect(() => {
        function onlineHandler() {
            setIsOffline(false);
        }

        function offlineHandler() {
            setIsOffline(true);
        }

        window.addEventListener("online", onlineHandler);
        window.addEventListener("offline", offlineHandler);


        return () => {
            window.removeEventListener("online", onlineHandler);
            window.removeEventListener("offline", offlineHandler);
        };
    }, []);

    const setFeedbackWithFormattedMessage = (feedback: Feedback | undefined) => {
        if (feedback) {
            const showLoginPrompt =
                feedback.showLoginPromptOnInvalidCredentials && feedback.message === "INVALID_CREDENTIALS"
            setFeedback({
                type: feedback.type,
                message: message(feedback.message),
                showLoginPromptOnInvalidCredentials: showLoginPrompt
            })
            return
        }
        setFeedback(undefined)
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const onNavigate = () => {
        setFeedback(undefined)
        setMenuOpen(false)
    }

    return (
        <AppContext.Provider
            value={{
                menuOpen,
                setMenuOpen,
                isOffline,
                setIsOffline,
                feedback,
                setFeedback: setFeedbackWithFormattedMessage,
                toggleMenu,
                onNavigate
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
