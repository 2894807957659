import React, { useContext, FunctionComponent } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { AppContext } from "../providers/AppProvider"

const ConnectivityChecker: FunctionComponent = () => {
    const { Message } = useContext(AuthContext)
    const { isOffline } = useContext(AppContext)


    return (
        <div className="ConnectivityMessage">
            {isOffline && (
                <div className="noConnection">
                    <div><div className="loader" /></div>
                    <div><h2>{Message("noInternetConnection")}</h2></div>
                    <div><h3>{Message("functionalWhenReconnect")}</h3></div>
                </div>
            )}
        </div>
    )
}

export default ConnectivityChecker
