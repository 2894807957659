import { useEffect, useState } from "react"
import Worker from "interfaces/Worker"
import { executeRequest, RequestType } from "../api/APIUtils"
import endpoints from "../api/endpoints"

type AvatarResponse = {
    success: boolean
    imageBytes: string
}

const defaultKeySuffix: string = "_cropped"
const fallbackKeySuffix: string = "_290"

const executeGetAvatarRequest = (avatarPath: string): Promise<AvatarResponse> => {
    try {
        console.log(`calling execute request: ${avatarPath}`)
        return executeRequest({
            endpoint: `${endpoints.worker.GET_AVATAR}?key=${avatarPath}`,
            withApiKey: true,
            requestType: RequestType.GET
        })
    } catch (error: any) {
        console.error(error)
        return new Promise<AvatarResponse>(resolve => {
            resolve({ success: false, imageBytes: "" })
        })
    }
}

export default function useAvatar(worker: Worker): React.ReactNode {
    const [avatarData, setAvatarData] = useState<string>()
    const [requestComplete, setRequestComplete] = useState<boolean>(false)

    useEffect(() => {
        if (!worker.avatarPath) {
            setRequestComplete(true)
            setAvatarData(undefined)
            return
        }

        const getAvatar = async () => {
            try {
                let avatarResponse: AvatarResponse = await executeGetAvatarRequest(worker.avatarPath + defaultKeySuffix)
                if (!avatarResponse || !avatarResponse.success) {
                    avatarResponse = await executeGetAvatarRequest(worker.avatarPath + fallbackKeySuffix)
                }

                if (avatarResponse && avatarResponse.success) {
                    setAvatarData(
                        "data:image/png;base64," + avatarResponse.imageBytes
                    )
                }
            } catch(e) {
                console.error(e)
            } finally {
                setRequestComplete(true)
            }
        }

        getAvatar()
    }, [worker])

    if (!requestComplete) {
        return <div className="loader" />
    }

    if (requestComplete && avatarData) {
        return <img src={avatarData} alt="" />
    }

    return null
}
