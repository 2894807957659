import { defaultMessages } from "./defaultMessages"
import { findMessage } from "../../utils/Tools"

const messages: { [key: string]: string } = {
    orgName: "Staffline",
    welcome: "Log in to Staffline",
    contactSupport: "Please contact a Staffline representative and sign in another way.",
    qrInterfaceDetails: "Use QR codes created in Universe. Presenting the worker's QR code to the tablet's camera matches to the worker's profile. Attaching the device to main power is recommended.",
}

const staffline = (key: string, attr: string[]): string => {
    return findMessage(key, messages, attr) || defaultMessages(key, attr)
}

export default staffline
