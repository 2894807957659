import usePortal from "hooks/usePortal";
import React, { useRef, PropsWithChildren, useState } from "react"
import ReactDOM from "react-dom";
import TimerButton from "./widgets/TimerButton"
import { useInterval } from "../utils/Tools"

interface Props {
    onAutoAdvanceOnClick?: () => void
    autoAdvanceClickInMs?: number
    autoAdvanceButtonText?: string
    autoAdvanceMessageText?: string
    autoAdvanceDisabled?: boolean
    autoAdvanceLoading?: boolean
}

export default function Modal({ onAutoAdvanceOnClick, autoAdvanceClickInMs, autoAdvanceButtonText, autoAdvanceMessageText, autoAdvanceDisabled, autoAdvanceLoading, children }: PropsWithChildren<Props>) {
    const modalRef = useRef<HTMLDivElement>(null);
    const modalsPortal = usePortal("modals")
    const [showAutoAdvance, setShowAutoAdvance] = useState<boolean>(false)
    let delay = 5

    useInterval(
        () => {
            if ( delay !== 0 ) {
                delay --
            }
            else {
                setShowAutoAdvance(true)
            }
        },
        !showAutoAdvance ? 1000 : null
    )

    return ReactDOM.createPortal((
        <div>
            <div className="modal-backdrop show">
            </div>

            <div className="modal-container">
                <div className="modal" ref={modalRef}>
                    {children}
                </div>
                {onAutoAdvanceOnClick && autoAdvanceButtonText && showAutoAdvance &&(
                    <div className="modal-auto-advance">
                        <TimerButton
                            className={"GrayTimerButton"}
                            onClick={onAutoAdvanceOnClick}
                            loading={autoAdvanceLoading}
                            autoClickInMs={autoAdvanceClickInMs}
                            text={autoAdvanceButtonText}
                            displayedCountDownMessage={autoAdvanceMessageText}
                            disabled={autoAdvanceDisabled}
                        />
                    </div>
                )}
            </div>
        </div>
    ), modalsPortal)
}
