import { defaultMessages } from "./defaultMessages"
import { findMessage } from "../../utils/Tools"

const messages: { [key: string]: string } = {
    orgName: "JoinedUp",
    welcome: "Log in to JoinedUp"
}

const joinedUp = (key: string, attr: string[]): string => {
    return findMessage(key, messages, attr) || defaultMessages(key, attr)
}

export default joinedUp
