import { AuthContext } from "providers/AuthProvider"
import React, { useContext, useState } from "react"
import Modal from "./Modal"
import { actionLabels, getActionLabel } from "./ShiftActions/actions"
import LoaderButton from "./widgets/LoaderButton"

interface Props {
    onConfirmReason: (reason: string) => void
    loading: boolean
}

export default function StoppingShiftEarlyReasonModal({ onConfirmReason, loading }: Props) {
    const { Message } = useContext(AuthContext)
    const [reason, setReason] = useState<string>("")

    return (
        <Modal>
            <div className="stoppingShiftEarlyReasonModal">
                {Message("shiftEarlierEnding")}
                <textarea
                    onChange={e => {
                        setReason(e.target.value)
                    }}
                />
                <div className="textAlignCenter">
                    <LoaderButton
                        disabled={!reason}
                        onClick={() => onConfirmReason(reason)}
                        loading={loading}
                        className="wider"
                    >
                        {Message(getActionLabel(actionLabels.STOP_SHIFT, loading))}
                    </LoaderButton>
                </div>
            </div>
        </Modal>
    )
}
