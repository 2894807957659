import { ConnectedUser } from "../interfaces/ConnectedUser"
import ClientStorage from "../utils/ClientStorage"
import { OperationalModeConfig } from "../interfaces/OperationalModeConfig"
import { WhiteLabelConfig, defaultWhiteLabelConfig } from "../interfaces/WhiteLabelConfig"

const connectedUserKey = "connectedUser"
const operationalModeKey = "operationalModeConfig"
const whiteLabelKey = "whiteLabelConfig"

const login = (user: ConnectedUser): void => {
    ClientStorage.setItem(connectedUserKey, btoa(JSON.stringify(user)))
}

const logout = (): void => {
    ClientStorage.removeItem(connectedUserKey)
}

const getConnectedUser = (): ConnectedUser | undefined => {
    let userJson: string | null = ClientStorage.getItem(connectedUserKey)
    if (userJson) {
        userJson = atob(userJson)
        try {
            return JSON.parse(userJson)
        } catch (e) {
            console.error("Invalid JSON for connected user, logging out...")
            logout()
        }
    }
}

const setOperationalModeConfig = (operationalModeConfig: OperationalModeConfig) => {
    ClientStorage.setItem(operationalModeKey, btoa(JSON.stringify(operationalModeConfig)))
}

const removeOperationalModeConfig = () => {
    ClientStorage.removeItem(operationalModeKey)
}

const getOperationalModeConfig = (): OperationalModeConfig | undefined => {
    let operationalModeConfigJson: string | null = ClientStorage.getItem(operationalModeKey)
    if (operationalModeConfigJson) {
        operationalModeConfigJson = atob(operationalModeConfigJson)
        try {
            return JSON.parse(operationalModeConfigJson)
        } catch (e) {
            console.error("Invalid JSON for operational mode config, removing config...")
            removeOperationalModeConfig()
        }
    }
}

const setWhiteLabelConfig = (whiteLabelConfig: WhiteLabelConfig) => {
    ClientStorage.setItem(whiteLabelKey, btoa(JSON.stringify(whiteLabelConfig)))

    document.getElementById("style_wl")?.setAttribute("href", whiteLabelConfig?.context + "/app.css")
    document.getElementById("icon_wl")?.setAttribute("href", whiteLabelConfig?.context + "/favicon.png")
    document.getElementById("at_icon_wl")?.setAttribute("href", whiteLabelConfig?.context + "/logo192.png")
}

const removeWhiteLabelConfig = () => {
    ClientStorage.removeItem(whiteLabelKey)
}

const getWhiteLabelConfig = (): WhiteLabelConfig => {
    let whiteLabelConfigJson: string | null = ClientStorage.getItem(whiteLabelKey)
    if (whiteLabelConfigJson) {
        whiteLabelConfigJson = atob(whiteLabelConfigJson)
        try {
            return JSON.parse(whiteLabelConfigJson)
        } catch (e) {
            console.error("Invalid JSON for white label config, removing config...")
            removeWhiteLabelConfig()
        }
    }
    return defaultWhiteLabelConfig
}

const Auth = {
    login,
    logout,
    getConnectedUser,
    getOperationalModeConfig,
    setOperationalModeConfig,
    removeOperationalModeConfig,
    getWhiteLabelConfig,
    setWhiteLabelConfig
}

export default Auth
