import React, { FunctionComponent } from "react"
import clsx from "clsx"

interface Props {
    onClick: () => void
    disabled?: boolean
    loading: boolean
    children: React.ReactNode
    className?: string
}

const LoaderButton: FunctionComponent<Props> = ({ onClick, disabled, loading, children, className }) => {
    return (
        <button
            onClick={onClick}
            disabled={disabled || loading}
            className={clsx("loaderButton", className, { loading: loading })}
        >
            {loading && <div className="loader"></div>}
            {children}
        </button>
    )
}

export default LoaderButton
