const messages: { [key: string]: string } = {
    MISSING_PARAMETERS: "Parameters missing from the request",
    RESOURCE_NOT_FOUND: "The requested resource could not be found",
    INVALID_CREDENTIALS: "Invalid credentials were supplied",
    FACE_NOT_FOUND: "Your face could not be found",
    SHIFT_NOT_FOUND: "Your shift could not be found",
    UNEXPECTED_ERROR: "An unexpected error occurred"
}

export const message = (key: string | undefined): string => {
    if (!key) {
        return ""
    }
    return messages[key] || key
}
