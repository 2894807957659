import { AuthContext } from "providers/AuthProvider"
import React, { useContext } from "react"

import "./ErrorMessageWithIcon.scss"

type Props = {
    title: string
    message: string
}

export default function ErrorMessageWithIcon({ title, message }: Props) {
    const { Message } = useContext(AuthContext)

    return (
        <div className="errorMessageWithIcon">
            <div className="icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" enableBackground="new 0 0 64 64">
                    <path
                        d="M32,2C15.432,2,2,15.432,2,32C1.999,48.568,15.432,62,32,62s30.001-13.432,30-30C62.001,15.432,48.568,2,32,2z M9,38V26h46
	v12H9z"
                        fill="#e53935"
                    />
                </svg>
            </div>
            <div className="title">{title}</div>
            <p className="bigger">{message}</p>
            <p className="bigger">{Message("pleaseContactSupervisor")}</p>
        </div>
    )
}
