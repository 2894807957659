import React, { FunctionComponent, useContext } from "react"
import "../assets/app.css"
import { BrowserRouter } from "react-router-dom"
import { AuthContext } from "../providers/AuthProvider"
import FeedbackBar from "./FeedbackBar"
import Main from "./Main"
import Footer from "./Footer"
import { useServiceWorker } from "../useServiceWorker"
import ConnectivityChecker from "./ConnectivityChecker"

const SystemMessage: FunctionComponent = ({ children }) => <div className="systemMessage">{children}</div>

const App: FunctionComponent = () => {
    const { Message } = useContext(AuthContext)
    const { showReload, reloadPage, initialised } = useServiceWorker()

    return (
        <>
            <div className="systemMessages">
                {initialised && <SystemMessage>{Message("offlineUse")}</SystemMessage>}
                {showReload && (
                    <SystemMessage>
                        {Message("newVersionAvailable")} <button onClick={reloadPage}>{Message("updateNow")}</button>
                    </SystemMessage>
                )}
            </div>
            <FeedbackBar />
            <ConnectivityChecker />
            <BrowserRouter>
                <Main />
            </BrowserRouter>
            <Footer/>
        </>
    )
}

export default App
