import React, { useContext, useEffect } from "react"
import { InteractionMode } from "interfaces/InteractionMode"
import { AuthContext } from "providers/AuthProvider"
import Countdown from "./Countdown"

export default function NotEnabled({ onRestart, interactionMode, idleRestartSeconds }: {
    onRestart: () => void,
    interactionMode?: InteractionMode,
    idleRestartSeconds: number
}) {
    const { operationalModeConfig, Message} = useContext(AuthContext)
    const resetCountdown = operationalModeConfig?.resetCountdown || 5

    useEffect(() => {
        const timeoutId = setTimeout(onRestart, 1000 * idleRestartSeconds)
        return () => clearTimeout(timeoutId)
    }, [onRestart, idleRestartSeconds])

    return (
        <div className="contentBox textAlignCenter">
            <p className="marginBottom14"> {Message("tabletsNotEnabled")}</p>
            <p> {Message("contactSupport")} </p>

            <div className="bottomBar">
                <div className="contentBox textAlignCenter">
                    {interactionMode === InteractionMode.CONTACTLESS
                        ? <Countdown initialCountdownSeconds={resetCountdown} displayedMessage="stepAside" onCountdownComplete={onRestart} />
                        : (
                            <button className="grey" onClick={onRestart}>
                                {Message("back")}
                            </button>
                        )
                    }
                </div>
            </div>
        </div>
    )
}