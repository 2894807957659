import React, { useEffect, useState, FunctionComponent } from "react"
import moment, { Moment } from "moment"
import DateTimeOutput from "./timezone/DateTimeOutput"

interface Props {
    toTz: string,
    timezonesEnabled: boolean
}

const Clock: FunctionComponent<Props> = ({ toTz, timezonesEnabled}) => {
    const [clock, setClock] = useState<Moment>(moment.tz(toTz))

    useEffect(() => {
        const clock = setInterval(() => {
            setClock(moment.tz(toTz))
        }, 1000)
        return () => {
            clearInterval(clock)
        }
    }, [toTz])

    return (
        <span className="clock">
            <DateTimeOutput
                id={"clock"}
                dateTime={clock}
                targetFormat={"HH:mm:ss"}
                tooltipFormat={"HH:mm:ss"}
                timezonesEnabled={timezonesEnabled}
            />
        </span>
    )
}

export default Clock
