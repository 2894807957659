import { Moment } from "moment"
import React, { useContext } from "react"
import { userTimezone } from "./TimezoneConversion"
import { AuthContext } from "../../providers/AuthProvider"

export interface Props {
    id: string
    dateTime?: Moment
    hideTimezone?: boolean
    targetFormat?: string
    tooltipFormat?: string
    hideTitle?: boolean
    abbrClassName?: string
    timezonesEnabled: boolean
}

/**
 * Format and display datetime only if targetFormat specified.
 *
 * Tooltip format is DateFormat.SHORT_DATE_WITH_TIME by default, unless DateFormat.SHORT_DATE is specified.
 */
const DateTimeOutput = ({
    id,
    dateTime,
    hideTimezone,
    targetFormat,
    tooltipFormat,
    hideTitle,
    timezonesEnabled
}: Props) => {
    const { Message } = useContext(AuthContext)

    if (!dateTime) {
        return null
    }

    const userDateTime = dateTime.clone().tz(userTimezone)
    const timezoneDiffers = dateTime.utcOffset() !== userDateTime.utcOffset()
    let titleMessage = ""
    if (!hideTitle) {
        if (timezonesEnabled) {
            const formattedUserDateTime = userDateTime.format(tooltipFormat || "HH:mm")

            if (timezoneDiffers) {
                titleMessage = `${Message("yourTime")} ${formattedUserDateTime} ${userDateTime.zoneAbbr()}`
            } else {
                titleMessage = formattedUserDateTime
            }
        } else {
            titleMessage = dateTime.format(tooltipFormat || "HH:mm")
        }
    }

    const title = titleMessage !== "" ? titleMessage : undefined

    return (
        <span title={title} id={id} data-testid="convertedTz">
            {targetFormat && dateTime.format(targetFormat)}
            {timezonesEnabled && timezoneDiffers && !hideTimezone && (
                <span className="timezoneAbbreviation" data-testid="convertedTzAbbrev" id="timezoneAbbreviation">
                    {dateTime.zoneAbbr()}
                </span>
            )}
        </span>
    )
}

export default DateTimeOutput
