import React, { Fragment, useContext, useEffect, useState, FunctionComponent } from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { AuthContext } from "../providers/AuthProvider"
import { ConnectedUser } from "../interfaces/ConnectedUser"
import { OperationalModeConfig } from "../interfaces/OperationalModeConfig"
import routes from "../routes"
import Login from "./Login"
import CapturePhoto from "./CapturePhoto"
import ShiftActions from "./ShiftActions"
import SiteSelector from "./SiteSelector"
import ProtectedRoute, { ProtectedRouteType } from "./ProtectedRoute"
import SlidingMenu from "./SlidingMenu"
import Cookies from "../providers/CookieProvider"
import LoginWithSso from "./LoginWithSso"
import { executeRequest, RequestType } from "api/APIUtils"
import endpoints from "api/endpoints"
import ClientStorage from "../utils/ClientStorage"

interface GetEnableSSOResponse {
    success: boolean
    isSsoLoginEnabled: boolean
}

const redirectIfRequired = (
    connectedUser: ConnectedUser | undefined,
    operationalModeConfig: OperationalModeConfig | undefined
) => {
    if (connectedUser) {
        return <Redirect to={routes.SELECT_SITE} />
    }

    if (operationalModeConfig && Cookies.get("azureToken") === undefined && Cookies.get("azureError") === undefined) {
        return <Redirect to={routes.CAPTURE_PHOTO} />
    }
}

const Main: FunctionComponent = () => {
    const { connectedUser, operationalModeConfig, whiteLabelConfig, Message } = useContext(AuthContext)
    const [isSsoLoginEnabled, setIsSsoLoginEnabled] = useState<boolean | undefined>(undefined)
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const getSsoLoginEnabled = async () => {
            setIsLoading(true)
            try {
                const { success, isSsoLoginEnabled }: GetEnableSSOResponse = await executeRequest({
                    endpoint: endpoints.featureSwitches.GET_ENABLE_SSO_LOGIN_PAGE,
                    requestType: RequestType.GET
                })

                if (success) {
                    setIsSsoLoginEnabled(isSsoLoginEnabled)
                    ClientStorage.setItem("IsSsoLoginEnabled", isSsoLoginEnabled)
                } else {
                    setIsSsoLoginEnabled(false)
                    ClientStorage.setItem("IsSsoLoginEnabled", false)
                }
            } catch (error: any) {
                setIsSsoLoginEnabled(false)
                ClientStorage.setItem("IsSsoLoginEnabled", false)
            } finally {
                setIsLoading(false)
            }
        }

        getSsoLoginEnabled()
    }, [])

    const outputLoginRoute = () =>
        isLoading ? <div className="loader loader-xl" /> : isSsoLoginEnabled ? <LoginWithSso /> : <Login />

    return (
        <Fragment>
            {redirectIfRequired(connectedUser, operationalModeConfig)}
            <nav className="topBar">
                <img src={whiteLabelConfig.context + "/logo.png"} alt={Message("orgName")} />
                <SlidingMenu />
            </nav>
            <div className="pageContent">
                <Switch>
                    <Route path={routes.LOGIN}>
                        <Login />
                    </Route>
                    <Route path={routes.LOGIN_WITH_SSO}>
                        <LoginWithSso />
                    </Route>
                    <Route path={routes.SELECT_SITE}>
                        <ProtectedRoute type={ProtectedRouteType.AGENT}>
                            <SiteSelector />
                        </ProtectedRoute>
                    </Route>
                    <Route path={routes.CAPTURE_PHOTO}>
                        <ProtectedRoute type={ProtectedRouteType.WORKER}>
                            <CapturePhoto />
                        </ProtectedRoute>
                    </Route>
                    {operationalModeConfig && (
                        <Route path={routes.SHIFT_FOUND}>
                            <ProtectedRoute type={ProtectedRouteType.WORKER}>
                                <ShiftActions mode={operationalModeConfig.interactionMode} />
                            </ProtectedRoute>
                        </Route>
                    )}
                    <Route path="/">{outputLoginRoute()}</Route>
                </Switch>
            </div>
        </Fragment>
    )
}

export default Main
