import Cookie from 'universal-cookie'

const cookie = new Cookie()

class CookieProvider {
    get(key: string) {
        return cookie.get(key)
    }

    set(key: string, value: string, options:Object) {
        return cookie.set(key, value, options)
    }

    remove(key: string, options:Object){
        return cookie.remove(key, options)
    }

    removeSSO(key: string){
        return cookie.remove(key, { domain: cookie.get("ssoDomain") })
    }
}

export default new CookieProvider()