import React, { Fragment, useContext, FunctionComponent } from "react"
import { Link } from "react-router-dom"
import routes from "../routes"
import { AuthContext } from "../providers/AuthProvider"
import { AppContext } from "../providers/AppProvider"
import ClientStorage from "../utils/ClientStorage"

const SlidingMenu: FunctionComponent = () => {
    const { connectedUser, onLogout, Message } = useContext(AuthContext)
    const { toggleMenu, menuOpen } = useContext(AppContext)

    return (
        <Fragment>
            <div className="menuButton" onClick={toggleMenu}>
                <div className="bar" />
                <div className="bar" />
                <div className="bar" />
            </div>
            <div className={`slidingMenu ${menuOpen ? "open" : ""}`}>
                <ul>
                    {!connectedUser && (
                        <Fragment>
                            <Link to={routes.CAPTURE_PHOTO}>
                                <li>{Message("workerAttendance")}</li>
                            </Link>
                            <Link to={ClientStorage.getItem('IsSsoLoginEnabled') ? routes.LOGIN_WITH_SSO : routes.LOGIN}>
                                <li>{Message("agentLogin")}</li>
                            </Link>
                        </Fragment>
                    )}
                    {connectedUser && <li onClick={onLogout}>{Message("logout")}</li>}
                </ul>
            </div>
            {menuOpen && <div className="overlay" onClick={toggleMenu}></div>}
        </Fragment>
    )
}

export default SlidingMenu
