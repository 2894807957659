import NodeCache from "node-cache"
import { executeRequest, RequestType } from "../api/APIUtils"
import endpoints from "../api/endpoints"

const DEVELOPMENT_CACHE_TIMEOUT = 60 // 1 minute
const PROD_CACHE_TIMEOUT = DEVELOPMENT_CACHE_TIMEOUT * 10 // 10 minutes

const switchCache = new NodeCache({
    stdTTL: process.env.NODE_ENV === "development" ? DEVELOPMENT_CACHE_TIMEOUT : PROD_CACHE_TIMEOUT,
    useClones: false,
    deleteOnExpire: true
})

export type FeatureSwitch =
    | "ENABLE_EARLY_STOP_SHIFT_REASON"
    | "TEMPORARY_FEATURE_ENABLE_TIMEZONES"
    | "ENABLE_EARLY_STOP_SHIFT_REASON"

export type FeatureSwitches = Set<FeatureSwitch>

export async function getFeatureSwitches(orgId: number): Promise<FeatureSwitches> {
    if (!switchCache.has(orgId + "")) {
        const response = await executeRequest({
            endpoint: `${endpoints.featureSwitches.GET_FOR_ORG}?orgId=${orgId}`,
            withApiKey: true,
            requestType: RequestType.GET
        })
        const featureSwitches: FeatureSwitch[] = response.featureSwitches
        const featureSwitchesSet: FeatureSwitches = new Set<FeatureSwitch>(featureSwitches)
        switchCache.set(orgId + "", featureSwitchesSet)
    }

    return switchCache.get(orgId + "") as FeatureSwitches || new Set()
}
