import React, { Fragment, FunctionComponent, useContext } from "react"
import { AuthContext } from "../providers/AuthProvider"
import logo from "../assets/poweredby.png"

const Footer: FunctionComponent = () => {
    const { Message } = useContext(AuthContext)

    return (
        <Fragment>
            <div className="footer">
                <div className="footerLeft">
                    <div className="inner"></div>
                </div>
                <div className="footerRight">
                    <div className="inner">
                        <div className="poweredBy">
                            <span>Powered by </span>
                            <a target="_blank" href="https://www.joinedup.com/" rel="noopener noreferrer">
                                <img src={logo} alt={Message("joinedUp")} />
                            </a>
                        </div>
                        &copy; {Message("copyRight")}
                    </div>
                    <div className="version">v.{process.env.REACT_APP_VERSION}</div>
                </div>
            </div>
        </Fragment>
    )
}

export default Footer
