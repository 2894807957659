import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { FormData, LoginRequest, LoginResponse } from "./Login"
import { AppContext, FeedbackType } from "../providers/AppProvider"
import { executeRequest, RequestType } from "../api/APIUtils"
import endpoints from "../api/endpoints"
import { ConnectedUser } from "../interfaces/ConnectedUser"
import { WhiteLabelConfig } from "../interfaces/WhiteLabelConfig"
import routes from "../routes"
import { useHistory } from "react-router"
import Cookies from "../providers/CookieProvider"
import LoaderButton from "./widgets/LoaderButton"

interface IdentityProvider {
    identityProviderName: string
    identityProviderUrl: string
    encodedIdentityRequest: string
    host: string
}

const DEFAULT_FORM_STATE = { email: "", password: "" }

const LoginWithSso = () => {
    const { onLogin, onLogout, onSetWhiteLabelConfig, whiteLabelConfig, Message } = useContext(AuthContext)
    const { setFeedback, feedback } = useContext(AppContext)
    const [formData, setFormData] = useState<FormData>(DEFAULT_FORM_STATE)
    const history = useHistory()

    const [ssoToken, setSSOToken] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false)
    const [trySSOLogin, setTrySSOLogin] = useState<boolean>(true)
    const [identityProviders, setIdentityProviders] = useState<IdentityProvider[]>([])

    useEffect(() => {
        if (Cookies.get("azureToken") !== undefined) {
            setSSOToken(Cookies.get("azureToken"))
            setFormData({ ...formData, email: Cookies.get("email") })
            Cookies.removeSSO("azureToken")
            Cookies.removeSSO("email")
            Cookies.removeSSO("ssoDomain")
        }
        if (Cookies.get("azureError") !== undefined) {
            setFeedback({
                type: FeedbackType.ERROR,
                message: Cookies.get("azureError").replaceAll("_", " ")
            })
            setFormData(DEFAULT_FORM_STATE)
            setLoading(false)
            Cookies.removeSSO("azureError")
            Cookies.removeSSO("ssoDomain")
        }

        onLogout()
    }, [])

    useEffect(() => {
        if (ssoToken !== "") {
            login()
        }
    }, [ssoToken])

    useEffect(() => {
        login()
        setTrySSOLogin(false)
    }, [])

    const login = async () => {
        if (emptyForm() && !identityProviders) {
            return
        }

        const loginRequest: LoginRequest = {
            email: formData.email,
            password: formData.password,
            trySSOLogin: trySSOLogin,
            ssoToken: ssoToken,
            ssoCallback: window.location.origin + "/loginWithSso",
            loginSSO: true
        }

        try {
            setLoading(true)
            const { success, samlRequest, user, errors }: LoginResponse = await executeRequest({
                endpoint: endpoints.auth.LOGIN,
                requestType: RequestType.POST,
                params: loginRequest,
                passthroughError: true
            })

            if (success) {
                if (samlRequest) {
                    setIdentityProviders([samlRequest])
                } else {
                    const connectedUser: ConnectedUser = {
                        id: user.id,
                        email: user.email,
                        tempOrgId: user.orgId,
                        tempWhitelabelContext: user.tempWhitelabelContext,
                        token: user.token
                    }
                    onLogin(connectedUser)
                    if (whiteLabelConfig.context !== connectedUser.tempWhitelabelContext) {
                        const tempWhiteLabelConfig: WhiteLabelConfig = {
                            context: connectedUser.tempWhitelabelContext,
                            lang: whiteLabelConfig.lang
                        }
                        onSetWhiteLabelConfig(tempWhiteLabelConfig)
                    }
                    history.push(routes.SELECT_SITE)
                }
            } else {
                if (errors.key === "NO_SSO_AUTH") {
                    setTrySSOLogin(false)
                } else {
                    setFeedback({
                        type: FeedbackType.ERROR,
                        message: errors.message
                    })
                }
            }
        } catch (error: any) {
            setFeedback({
                type: FeedbackType.ERROR,
                message: error.message
            })
            setFormData(DEFAULT_FORM_STATE)
            console.error(error)
        } finally {
            setLoading(false)
        }
    }

    const goToForgotPassword = () => {
        window.open(process.env.REACT_APP_FORGOT_PASSWORD_URL, "_blank")
    }

    const outputIdentityProviders = () => {
        const hideElement = function(element: any) {
			element.target.style.display = "none"
		}

        return (
            identityProviders.length > 0 &&
            identityProviders.map((identityProvider: any) => {
                const ssoLogoPath =
                    identityProvider.identityProviderName.toLowerCase() === "staffline"
                        ? "none"
                        : `ssoHosts/${identityProvider.host.toLowerCase()}.svg`
                return (
                    <React.Fragment key={identityProvider.identityProviderName}>
                        <form
                            action={identityProvider.identityProviderUrl}
                            method="POST"
                            className="form-vertical login loginWithSso"
                        >
                            <input type="hidden" name="SAMLRequest" value={identityProvider.encodedIdentityRequest} />
                            <button key={identityProvider.identityProviderName} type="submit">
                                <img
                                    src={ssoLogoPath}
                                    alt={`${identityProvider.host} logo`}
                                    className={identityProvider.identityProviderName}
                                    onError={hideElement}
                                />
                                {Message(`loginSsoContinueWith`)} {identityProvider.identityProviderName.toLowerCase() === "staffline" ? " Single Sign On" : identityProvider.identityProviderName}
                            </button>
                        </form>
                    </React.Fragment>
                )
            })
        )
    }

    const onFieldFocus = () => {
        if (feedback) {
            setFeedback(undefined)
        }
    }

    const emptyForm = (): boolean => {
        return formData.email === "" || formData.password === ""
    }

    return (
        <div className="loginWrapper">
            <div>
                <p className="welcomeMessage" dangerouslySetInnerHTML={{ __html: Message("loginWelcome") }} />
            </div>
            <fieldset className="fieldset">
                <div className="innerFieldset">
                    <h1>{Message("loginTitle")}</h1>
                    <div className="alertsWrapper">
                        <div className="alert alert-info">{Message("loginSso")}</div>
                    </div>
                    <div className="buttonsWrapper">{outputIdentityProviders()}</div>

                    <div className="loginForm">
                        <div className="field">
                            <label htmlFor="email">{Message("email")}</label>
                            <input
                                type="email"
                                name="email"
                                aria-label="email"
                                autoComplete="disabled"
                                value={formData.email}
                                placeholder={Message("emailPlaceHolder")}
                                onFocus={onFieldFocus}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setFormData({ ...formData, email: e.currentTarget.value })
                                }
                            />
                        </div>
                        <div className="field">
                            <label htmlFor="password">{Message("password")}</label>
                            <input
                                type="password"
                                name="password"
                                aria-label="password"
                                value={formData.password}
                                placeholder={Message("passwordPlaceHolder")}
                                onFocus={onFieldFocus}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                    setFormData({ ...formData, password: e.currentTarget.value })
                                }
                            />
                        </div>
                        <div className="loginButtonWrapper">
                            <div onClick={goToForgotPassword}>{Message("loginForgottenPassword")}</div>
                            <LoaderButton onClick={login} loading={loading} disabled={emptyForm()}>
                                {Message("login")}
                            </LoaderButton>
                        </div>
                    </div>
                </div>
            </fieldset>
        </div>
    )
}

export default LoginWithSso
