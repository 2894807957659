import React, { Fragment, useContext, useEffect, FunctionComponent } from "react"
import { AppContext, FeedbackType } from "../providers/AppProvider"
import { AuthContext } from "../providers/AuthProvider"
import { BrowserRouter, Link } from "react-router-dom"
import routes from "../routes"
import ClientStorage from "../utils/ClientStorage"

interface Props {
    timeout?: number
}

const defaultTimeout: number = 5000

const FeedbackBar: FunctionComponent<Props> = ({ timeout }) => {
    const { Message } = useContext(AuthContext)
    const { setFeedback, feedback } = useContext(AppContext)

    useEffect(() => {
        const errorTimer = setTimeout(() => {
            setFeedback(undefined)
        }, timeout || defaultTimeout)
        return () => {
            clearTimeout(errorTimer)
        }
    }, [feedback, setFeedback, timeout])

    if (!feedback) {
        return null
    }

    const feedbackMessage = feedback.message || Message("errorOccurred")

    return (
        <div
            className={`feedbackBar ${feedback?.type === FeedbackType.SUCCESS ? "success" : "errors"}`}
            onClick={() => setFeedback(undefined)}
        >
            {feedback?.showLoginPromptOnInvalidCredentials ? (
                <BrowserRouter>
                    <Link to={ClientStorage.getItem('IsSsoLoginEnabled') ? routes.LOGIN_WITH_SSO : routes.LOGIN}>{feedbackMessage} {Message("clickToLogin")}</Link>
                </BrowserRouter>
            ) : (
                <Fragment>{feedbackMessage}</Fragment>
            )}
        </div>
    )
}

export default FeedbackBar
