import { useState, useCallback, useEffect } from "react"
import * as serviceWorkerRegistration from "serviceWorkerRegistration"

export const useServiceWorker = () => {
    const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)
    const [initialised, setInitialised] = useState <boolean>(false)
    const [showReload, setShowReload] = useState <boolean>(false)

    // This tells the service worker to skip the waiting phase and then reloads the page
    const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
        setShowReload(true)
        setWaitingWorker(registration.waiting)
    }, [])

    // Register the service worker
    const reloadPage = useCallback(() => {
        waitingWorker?.postMessage({ type: "SKIP_WAITING" })
        setShowReload(false)
        window.location.reload()
    }, [waitingWorker])
    
    useEffect(() => {
        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: https://cra.link/PWA
        serviceWorkerRegistration.register({
            onSuccess: () => {
                setInitialised(true)
            },
            onUpdate: onSWUpdate,
        })
    }, [onSWUpdate])
    
    return { showReload, waitingWorker, reloadPage, initialised }
}