import React, { FunctionComponent, useState, useContext } from "react"
import { AuthContext } from "../providers/AuthProvider"
import { useInterval } from "../utils/Tools"

interface Props {
    onCountdownComplete: () => void
    initialCountdownSeconds: number
    displayedMessage: string
}

const Countdown: FunctionComponent<Props> = ({
    onCountdownComplete,
    initialCountdownSeconds,
    displayedMessage
}: Props) => {
    const { Message } = useContext(AuthContext)
    const [countdown, setCountdown] = useState<number>(Math.abs(Math.round(initialCountdownSeconds)))

    useInterval(() => {
        if (countdown === 0) {
            onCountdownComplete()
        } else {
            setCountdown(countdown - 1)
        }
    }, 1000)

    return (
        <div className="countdownRedirectBox">
            {Message(displayedMessage)} {countdown !== 0 ? countdown : ""}
        </div>
    )
}

export default Countdown
