import { instanceToTzMoment } from "components/timezone/TimezoneConversion"
import Shift from "interfaces/Shift"

export function addMomentTimesToShift(shift: Shift, timezonesEnabled: boolean): Shift {
    return {
        ...shift,
        bookedStartTimeTz: instanceToTzMoment(
            shift.bookedStartTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!,
        expectedStopTimeTz: instanceToTzMoment(
            shift.expectedStopTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!,
        actStartTimeTz: instanceToTzMoment(
            shift.actStartTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!,
        actStopTimeTz: instanceToTzMoment(
            shift.actStopTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!,
        breakStartTimeTz: instanceToTzMoment(
            shift.breakStartTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!,
        breakStopTimeTz: instanceToTzMoment(
            shift.breakStopTime,
            shift.siteTimezoneId,
            shift.instanceTimezoneId,
            timezonesEnabled
        )!
    }
}
