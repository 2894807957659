import React, { useContext, useState } from "react"
import clsx from "clsx"

import "./TimerButton.scss"
import { useInterval } from "../../utils/Tools"
import { AuthContext } from "../../providers/AuthProvider"

type Props = {
    text: string
    onClick: () => void
    autoClickInMs?: number
    loading?: boolean
    disabled?: boolean
    className?: string
    displayedCountDownMessage?: string
}

export default function TimerButton({ text, onClick, autoClickInMs, loading, disabled, className, displayedCountDownMessage }: Props) {
    const { Message } = useContext(AuthContext)
    const [countdown, setCountdown] = useState<number>((autoClickInMs ? (autoClickInMs/1000) : 0))
    
    let timerOn = autoClickInMs !== undefined && !(disabled || loading)
    
    useInterval(
        () => {
            if ( timerOn && countdown === 0) {
                timerOn=false
                onClick()
            } else {
                setCountdown(countdown - 1)
            }
        },
        timerOn ? 1000 : null
    )

    return (
        <div>
            { autoClickInMs && displayedCountDownMessage && countdown >= 0 && (
                <div> {Message(displayedCountDownMessage, [countdown.toString()])}</div>
            )}
            <button
                className={clsx("TimerButton button bigger", className, { timer: timerOn })}
                onClick={() => {
                    timerOn=false
                    onClick()
                }}
                style={{ animation: timerOn ? `timer ${autoClickInMs}ms linear` : "none" }}
                disabled={loading || disabled}
            >
                {loading && <div className="spinner" />}
                <div className="text">{text}</div>
            </button>
        </div>
    )
}
