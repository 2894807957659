import { AuthContext } from "providers/AuthProvider"
import { useContext, useEffect, useState } from "react"

type FeatureSwitches = {
    timezonesEnabled: boolean
    earlyStopReasonEnabled: boolean
    loadingFeatureSwitches: boolean
}

export default function useFeatureSwitches() {
    const { hasFeatureSwitch } = useContext(AuthContext)
    const [loadingFeatureSwitches, setLoadingFeatureSwitches] = useState<boolean>(true)
    const [timezonesEnabled, setTimezonesEnabled] = useState<boolean>(false)
    const [earlyStopReasonEnabled, setEarlyStopReasonEnabled] = useState<boolean>(false)

    useEffect(() => {
        const f = async () => {
            setTimezonesEnabled(await hasFeatureSwitch("TEMPORARY_FEATURE_ENABLE_TIMEZONES"))
            setEarlyStopReasonEnabled(await hasFeatureSwitch("ENABLE_EARLY_STOP_SHIFT_REASON"))
            setLoadingFeatureSwitches(false)
        }
        f()
    }, [hasFeatureSwitch])

    return { loadingFeatureSwitches, timezonesEnabled, earlyStopReasonEnabled } as FeatureSwitches
}
