import { findMessage } from "../../utils/Tools"

export const messages: { [key: string]: string } = {
    afternoon: "afternoon",
    agentLogin: "Agent login",
    appDescription: "Workers at the selected sites will be identified using the tablet's camera, and matched with a shift if they have one today.",
    atSiteFor: "at {param1} for",
    back: "Back",
    backWorkerAttendance: "Back to worker attendance",
    chooseSite: "Choose your sites to set up T&A",
    clickToLogin: "Click here to login.",
    contactlessInterface: "Contactless user interface",
    contactlessInterfaceDetails: "Workers will not need to touch the screen. Requires constant use of the onboard camera. Attaching the device to mains power is recommended.",
    contactlessWithBreaks: "Cannot have contactless mode with breaks.",
    contactSupervisorReset: "Please contact a supervisor to reset the device.",
    contactSupport: "Please contact support and sign in another way.",
    copyRight: "JoinedUp Group Ltd.",
    countdownStep: "Countdown step: ",
    done: "Done",
    email: "Email",
    emailPlaceHolder: "Enter your email address",
    errorOccurred: "An error occurred",
    fetchingSites: "Fetching sites...",
    forgotPassword: "Forgot your password?",
    frontCamera: "To record your time at work today, place yourself in front of the camera.",
    frontCameraButton: "To record your time at work today, place yourself in front of the camera and take your photo.",
    frontCameraQrMessage: "To record your time at work today, place your QR code identifier in front of the camera.",
    functionalWhenReconnect: "The app will become functional once you've reconnected to the internet",
    getSitesNoConnectUser: "Unable to get sites - no connected user",
    goIntoWorkerAttendance: "Once your sites are set, the tablet will go into \"worker attendance\" mode.",
    goToLogin: "Go to login",
    helloAgain: "Hello again {param1}.",
    idleRestartSeconds: "Auto confirmation after",
    initialisingCamera: "Initialising camera, please wait...",
    internalError: "Internal Error. Code: {param1}",
    joinedUp: "JoinedUp",
    loadingAction: "Loading...",
    login: "Log in",
    loginButton: "Log in",
    loginFieldEmail: "Email",
    loginFieldPassword: "Password",
    loginForgottenPassword: "Forgotten your password?",
    loginSso_AD_TEST_FOR_UPS: "Test provider for UPS",
    loginSso: "If your organisation has Single Sign On, choose the relevant provider. Otherwise enter your email and password below.",
    loginSsoContinueWith: "Continue with ",
    loginTitle: "Log in to JoinedUp",
    loginWelcome: "Welcome to JoinedUp’s on site tablet app. <br> JoinedUp uses facial recognition technology to enable you to capture shift start and stop times accurately and efficiently. <br> To get started, log in below.",
    logout: "Logout",
    missing2dContext: "Missing 2d context",
    morning: "morning",
    motionDetected: "Motion detected since last photo: ",
    newVersionAvailable: "There is a new version available.",
    noInternetConnection: "Oops, there's no internet connection",
    noOperationalModeConfig: "Unable to capture photo & find shift - no operational mode config",
    noSitesFound: "No sites were found, please contact support",
    notRecognise: "Sorry, we couldn't recognise you today.",
    offlineUse: "App is now cached for offline use.",
    orgName: "JoinedUp",
    password: "Password",
    passwordPlaceHolder: "Enter your password",
    pictureCountdown: "Picture countdown",
    pleaseContactSupervisor: "Please contact a supervisor.",
    pleaseSelect: "Please select...",
    qrInterface: "Contactless user interface with QR code detection",
    qrInterfaceDetails: "Use QR codes created in JoinedUp. Presenting the worker's QR code to the tablet's camera matches to the worker's profile. Attaching the device to mains power is recommended.",
    rememberStopShift: "Please remember to visit the tablet again when the shift is finished to stop the clock.",
    resetCountdown: "Reset countdown",
    samlRequestRedirect: "You will be redirected to {param1} to log in.",
    samlRequestRedirect1: "Click on the \"Log in\" button if you have not been redirected within 5 seconds.",
    searchingShift: "Searching for your shift...",
    seconds: "seconds",
    set: "Set",
    setSiteNoConnectUser: "Unable to set site - no connected user and/or site ID",
    settingSite: "Setting site...",
    shiftActualStart: "Actual start",
    shiftBreakStart: "Break start",
    shiftBreakStartSubmittedSuccessfully: "Break start time submitted successfully",
    shiftBreakStop: "Break stop",
    shiftBreakStopSubmittedSuccessfully: "Break stop time submitted successfully",
    shiftCheckStart: "Please check the details and start your shift now.",
    shiftEarlierEnding: "You are ending this shift earlier than expected. Please provide a reason below:",
    shiftFound: "We've found the shift you're working on today.",
    shiftNotFound: "We couldn't find a shift planned for you at this time at this site today.",
    shiftNotFoundShort: "Shift not found",
    photoNotFound: "The photo taken by the tablet doesn't match with a profile in JoinedUp.",
    photoNotFoundShort: "Photo not found",
    shiftStarted: "Thank you {param1}. Your shift has started.",
    shiftStartSubmittedSuccessfully: "Start time submitted successfully",
    shiftStartTime: "Start time",
    shiftStopCongrats: "Thank you {param1}. Your shift has stopped.",
    shiftStopNow: "Stop your shift now.",
    shiftStopSubmittedSuccessfully: "Stop time submitted successfully",
    shiftStopTime: "Stop time",
    siteSelectorDropdownPlaceholder: "Select one or more sites...",
    startAgain: "Start again",
    startBreak: "Start break",
    startingBreak: "Starting break...",
    startingShift: "Starting shift...",
    startShift: "Start shift",
    stepAside: "Please step aside and allow the next person to step up to the tablet.",
    stopBreak: "Stop break",
    stoppingBreak: "Stopping break...",
    stoppingShift: "Stopping shift...",
    stopShift: "Stop shift",
    tabletsNotEnabled: "You were recognised and a shift was found for you at this site, but the on-site tablets aren't enabled for this role.",
    takePhoto: "Take photo!",
    takePhotoStill: "The camera will take a photo when you're still for {param1} seconds. Watch the circles to the top right of the photo.",
    thisTabletIsConfiguredForSites: "This device is configured for the sites",
    timeNow: "Time now:",
    touchInterface: "Touch user interface",
    touchInterfaceDetails: "Workers will need to touch the screen to confirm their actions.",
    touchQrInterface: "Touch user interface with QR code detection",
    touchQrInterfaceDetails: "Use QR codes created in JoinedUp. Presenting the worker's QR code to the tablet's camera matches to the worker's profile. Attaching the device to mains power is recommended.",
    tryAgain: "Try again, if the error persists please contact support and sign in/out another way.",
    updateNow: "Update now",
    welcome: "Log in to JoinedUp",
    welcomeToSite: "Welcome to",
    welcomeWorker: "Good {param1}, {param2}!",
    workerAttendance: "Worker attendance",
    yourTime: "Your time: ",
    attestationNotice_YES: "Yes",
    attestationNotice_WAIVERED: "Waivered",
    attestationNotice_NO: "No",
    attestationNotice_NORESPONSE: "No response",
    confirm: "I confirm",
    shiftStopped: "Shift Stopped",
    shiftDuration:"This shift was {param1} hours {param2} mins",
    attestationNoticeStatement:"Did you take your allocated breaks?",
    attestationNoticeConfirm:"I confirm I took all my breaks",
    sessionEnd:"This session will end in {param1} seconds",
    nextWorker:"Next Worker"
}

export const defaultMessages = (key: string, attr: string[]): string => {
    return findMessage(key, messages, attr) || key
}

export default defaultMessages
