import { MotionDetector, MotionParams, MotionResult } from "../interfaces/MotionDetector"

export default class SimpleMotionDetector implements MotionDetector {
    previousMotionFrame: RGB[]

    detectMotion(params: SimpleMotionParams): MotionResult {
        const width = params.context.canvas.width
        const height = params.context.canvas.height

        if (width === 0 || height === 0) {
            return { motionDetected: false }
        }

        if (!params.sampleSize || params.sampleSize < 0) {
            params.sampleSize = 0
        }

        if (!params.pixelDiffThreshold || params.pixelDiffThreshold < 1) {
            params.pixelDiffThreshold = 1
        }

        const imageData = params.context.getImageData(0, 0, width, height).data

        let totalPositionsChanged = 0
        let totalPositionsInCanvas = 0
        let totalBlackPositions = 0
        let canvasFillingStyle : string | CanvasGradient | CanvasPattern
        const hadPreviousFrame = this.previousMotionFrame && this.previousMotionFrame.length > 0

        for (let y = 0; y < height; y += params.sampleSize) {
            for (let x = 0; x < width; x += params.sampleSize) {
                totalPositionsInCanvas++
                const pos = (x + y * width) * 4

                const red = imageData[pos]
                const green = imageData[pos + 1]
                const blue = imageData[pos + 2]

                const hexVal = new RGB(red, green, blue)
                if (red === 0 && green === 0 && blue === 0) {
                    totalBlackPositions++
                }

                if (this.previousMotionFrame[pos] && Math.abs(this.previousMotionFrame[pos].red - hexVal.red) > params.pixelDiffThreshold) {
                    canvasFillingStyle = `rgba(${red}, ${green}, ${blue}, 1)`
                    totalPositionsChanged++
                } else {
                    canvasFillingStyle = `rgba(255, 255, 255, 1)`
                }

                if (params.drawMotionToCanvas) {
                    params.context.fillStyle = canvasFillingStyle
                    params.context.fillRect(x, y, params.sampleSize, params.sampleSize)
                }

                this.previousMotionFrame[pos] = hexVal
            }
        }

        const motionDetected =
            this.enoughPixelsHaveChanged(totalPositionsChanged, totalPositionsInCanvas, params.motionFractionPercentageThreshold) &&
            this.allPixelsAreNotBlack(totalBlackPositions, totalPositionsInCanvas) &&
            this.tooManyPixelsHaveChanged(totalPositionsChanged, totalPositionsInCanvas)

        if (motionDetected) {
            this.previousMotionFrame = []
        }

        return { motionDetected: hadPreviousFrame && motionDetected }
    }

    enoughPixelsHaveChanged(totalPositionsChanged: number, totalPositionsInCanvas: number, motionFractionPercentageThreshold: number): boolean {
        return totalPositionsChanged/totalPositionsInCanvas * 100 > motionFractionPercentageThreshold
    }

    //  In practice, when pixel differences are detected, since the sensitivity is intentionally very low, we get fractions of a percent change.
    //  Any higher and we might have a hardware issue
    tooManyPixelsHaveChanged(totalPositionsChanged: number, totalPositionsInCanvas: number): boolean {
        return totalPositionsChanged/totalPositionsInCanvas < 0.25
    }

    //  Too many black pixels indicate the camera is off or covered... ignore when too high
    allPixelsAreNotBlack(totalBlackPositions: number, totalPositionsInCanvas: number): boolean {
        return totalBlackPositions !== totalPositionsInCanvas
    }

    constructor() {
        this.previousMotionFrame = []
    }
}

class RGB {
    red: number
    green: number
    blue: number

    constructor(red: number, green: number, blue: number) {
        this.red = red
        this.green = green
        this.blue = blue
    }
}

export interface SimpleMotionParams extends MotionParams {
    context: CanvasRenderingContext2D,
    drawMotionToCanvas: boolean,
    sampleSize: number,
    pixelDiffThreshold: number,
    motionFractionPercentageThreshold: number
}
