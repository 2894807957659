import React, { useContext, useMemo } from "react"
import moment from "moment"
import Shift from "interfaces/Shift"
import DateTimeOutput from "components/timezone/DateTimeOutput"
import { AuthContext } from "providers/AuthProvider"
import { SERVER_DATETIME_FORMAT } from "../timezone/TimezoneConversion"
import { addMomentTimesToShift } from "components/ShiftActions/tools"

type Props = {
    shift: Shift
    timezonesEnabled: boolean
}

export default function ShiftInfo({ shift, timezonesEnabled }: Props) {
    const { Message } = useContext(AuthContext)

    const modifiedShift = useMemo(() => addMomentTimesToShift(shift, timezonesEnabled), [shift, timezonesEnabled])

    return (
        <>
            {modifiedShift.bookedStartTimeTz && (
                <div className="shiftBlock first">
                    <div className="time">
                        <div>{moment(modifiedShift.bookedStartTime, SERVER_DATETIME_FORMAT).format("ddd D MMM")}</div>
                        <div className="muted">
                            {Message("shiftStartTime") + " "}
                            <DateTimeOutput
                                id={"bookedStartTime"}
                                dateTime={modifiedShift.bookedStartTimeTz}
                                targetFormat={"HH:mm"}
                                timezonesEnabled={timezonesEnabled}
                            />
                        </div>
                    </div>
                    <div className="shift">
                        <p>
                            <b>{modifiedShift.shiftTemplateAlias}</b> {Message("atSiteFor", [modifiedShift.siteName])}
                        </p>
                        <p>{modifiedShift.organisationName}</p>
                    </div>
                </div>
            )}
            {modifiedShift.actStartTimeTz && (
                <div className="shiftBlock green reduced">
                    <div className="time">{Message("shiftActualStart") + " "}</div>
                    <div className="shift">
                        <DateTimeOutput
                            id={"actualStartTime"}
                            dateTime={modifiedShift.actStartTimeTz}
                            targetFormat={"HH:mm (on ddd)"}
                            timezonesEnabled={timezonesEnabled}
                        />
                    </div>
                </div>
            )}
            {modifiedShift.breakStartTimeTz && (
                <div className="shiftBlock green reduced">
                    <div className="time">{Message("shiftBreakStart") + " "}</div>
                    <div className="shift">
                        <DateTimeOutput
                            id={"breakStartTime"}
                            dateTime={modifiedShift.breakStartTimeTz}
                            targetFormat={"HH:mm (on ddd)"}
                            timezonesEnabled={timezonesEnabled}
                        />
                    </div>
                </div>
            )}
            {modifiedShift.breakStopTimeTz && (
                <div className="shiftBlock green reduced">
                    <div className="time">{Message("shiftBreakStop") + " "}</div>
                    <div className="shift">
                        <DateTimeOutput
                            id={"breakStopTime"}
                            dateTime={modifiedShift.breakStopTimeTz}
                            targetFormat={"HH:mm (on ddd)"}
                            timezonesEnabled={timezonesEnabled}
                        />
                    </div>
                </div>
            )}
            {modifiedShift.actStopTimeTz && (
                <div className="shiftBlock green reduced">
                    <div className="time">{Message("shiftStopTime") + " "}</div>
                    <div className="shift">
                        <DateTimeOutput
                            id={"stopTime"}
                            dateTime={modifiedShift.actStopTimeTz}
                            targetFormat={"HH:mm (on ddd)"}
                            timezonesEnabled={timezonesEnabled}
                        />
                    </div>
                </div>
            )}
        </>
    )
}
