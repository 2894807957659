import React, { useState, FunctionComponent, useEffect, useCallback } from "react"
import { ConnectedUser } from "../interfaces/ConnectedUser"
import { OperationalModeConfig } from "../interfaces/OperationalModeConfig"
import { defaultWhiteLabelConfig, WhiteLabelConfig } from "../interfaces/WhiteLabelConfig"
import Auth from "../auth/Auth"
import { FeatureSwitch, getFeatureSwitches } from "utils/FeatureSwitches"
import { getMessage } from "utils/Tools"

interface Value {
    connectedUser?: ConnectedUser
    operationalModeConfig?: OperationalModeConfig
    whiteLabelConfig: WhiteLabelConfig
    onLogin: (user: ConnectedUser) => void
    onLogout: () => void
    onSetOperationalModeConfig: (operationalModeConfig: OperationalModeConfig) => void
    onSetWhiteLabelConfig: (whiteLabelConfig: WhiteLabelConfig) => void
    hasFeatureSwitch: (name: FeatureSwitch) => Promise<boolean>
    Message: (key: string, attr?: string[]) => string
}

export const defaultValue: Value = {
    onLogin: () => {},
    onLogout: () => {},
    onSetOperationalModeConfig: () => {},
    onSetWhiteLabelConfig: () => {},
    hasFeatureSwitch: async () => false,
    Message: getMessage(null),
    whiteLabelConfig: defaultWhiteLabelConfig
}

export const AuthContext = React.createContext(defaultValue)

export interface AuthProviderProps {
    initialConnectedUser?: ConnectedUser
    initialOperationalModeConfig?: OperationalModeConfig
    initialWhiteLabelConfig?: WhiteLabelConfig
}

export const AuthProvider: FunctionComponent<AuthProviderProps> = ({
    children,
    initialConnectedUser,
    initialOperationalModeConfig,
    initialWhiteLabelConfig
}) => {
    const [connectedUser, setConnectedUser] = useState<ConnectedUser | undefined>(
        Auth.getConnectedUser() || initialConnectedUser
    )
    const [operationalModeConfig, setOperationalModeConfig] = useState<OperationalModeConfig | undefined>(
        Auth.getOperationalModeConfig() || initialOperationalModeConfig
    )
    const [whiteLabelConfig, setWhiteLabelConfig] = useState<WhiteLabelConfig>(
        Auth.getWhiteLabelConfig() || initialWhiteLabelConfig || defaultWhiteLabelConfig
    )

    const hasFeatureSwitch = useCallback(
        async (name: FeatureSwitch): Promise<boolean> => {
            if (operationalModeConfig?.orgId) {
                const switches = await getFeatureSwitches(operationalModeConfig.orgId)
                return switches.has(name)
            } else {
                return false
            }
        },
        [operationalModeConfig]
    )

    const onLogin = (user: ConnectedUser) => {
        setConnectedUser(user)
        Auth.login(user)
    }

    const onLogout = () => {
        setConnectedUser(undefined)
        Auth.logout()
    }

    const onSetOperationalModeConfig = (operationalModeConfig: OperationalModeConfig) => {
        setOperationalModeConfig(operationalModeConfig)
        Auth.setOperationalModeConfig(operationalModeConfig)
    }

    const onSetWhiteLabelConfig = (whiteLabelConfig: WhiteLabelConfig) => {
        setWhiteLabelConfig(whiteLabelConfig)
    }

    useEffect(() => {
        Auth.setWhiteLabelConfig(whiteLabelConfig)
    }, [whiteLabelConfig])

    return (
        <AuthContext.Provider
            value={{
                onLogin,
                onLogout,
                connectedUser,
                operationalModeConfig,
                onSetOperationalModeConfig,
                whiteLabelConfig,
                onSetWhiteLabelConfig,
                hasFeatureSwitch,
                Message: getMessage(whiteLabelConfig || null)
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
