const getItem = (item: string): string | null => {
    return localStorage.getItem(item)
}

const setItem = (item: string, value: any): void => {
    localStorage.setItem(item, value)
}

const removeItem = (item: string): void => {
    localStorage.removeItem(item)
}

const removeItems = (items: string[]): void => {
    for (const item of items) {
        removeItem(item)
    }
}

const ClientStorage = {
    getItem,
    setItem,
    removeItem,
    removeItems
}

export default ClientStorage
