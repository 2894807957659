import Auth from "../auth/Auth"

const apiUrl: string | undefined = process.env.REACT_APP_API_URL

export enum RequestType {
    GET = "GET",
    POST = "POST"
}

export interface APIParameters {
    endpoint: string
    requestType?: RequestType
    params?: any
    withApiKey?: boolean
    withToken?: boolean
    passthroughError?: boolean
}

export const executeRequest = async ({
    endpoint,
    requestType,
    params,
    withApiKey,
    withToken,
    passthroughError = false
}: APIParameters): Promise<any> => {
    const headers: Headers = new Headers({
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json; charset=utf-8"
    })
    if (withApiKey) {
        const apiKey: string | undefined = Auth.getOperationalModeConfig()?.apiKey
        if (apiKey) {
            headers.append("Authorization", "ApiKey " + btoa(apiKey))
        }
    }
    if (withToken) {
        const token: string | undefined = Auth.getConnectedUser()?.token
        if (token) {
            headers.append("Authorization", "Bearer " + btoa(token))
        }
    }
    
    if (process.env.REACT_APP_VERSION) {
        headers.append("t-and-a-app-version", process.env.REACT_APP_VERSION)
    }

    const request: RequestInit = {
        method: requestType,
        headers
    }

    if (requestType === RequestType.POST) {
        request.body = JSON.stringify(params)
    }

    const response = await fetch(`${apiUrl}${endpoint}`, request)
    const responseJson = await response.json()
    if (!response.ok && !passthroughError) {
        throw new Error(responseJson.errors ? responseJson.errors.join() : response.statusText)
    }

    return responseJson
}
