import React, { StrictMode } from "react"
import ReactDOM from "react-dom"
import { AppProvider } from "providers/AppProvider"
import { AuthProvider } from "providers/AuthProvider"
import App from "./components/App"

ReactDOM.render(
    <StrictMode>
        <AppProvider>
            <AuthProvider>
                <App />
            </AuthProvider>
        </AppProvider>
    </StrictMode>,
    document.getElementById("root")
)
