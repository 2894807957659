import Shift from "interfaces/Shift"
import moment from "moment"
import { Action } from "./ShiftActions/actions"
import { asInstanceTzMoment } from "./timezone/TimezoneConversion"

export default function requiresReasonToStopEarly(
    action: Action,
    shift: Shift,
    earlyStopReasonEnabled: boolean
): boolean {
    if ("STOP_SHIFT" === action.action && earlyStopReasonEnabled && shift.expectedStopTime) {
        const instanceTimezoneId = shift.instanceTimezoneId
        const expectedStopTimeAtInstanceTz = asInstanceTzMoment(shift.expectedStopTime, instanceTimezoneId)
        const currentTimeAtInstanceTz = moment.tz(instanceTimezoneId)
        return expectedStopTimeAtInstanceTz?.isAfter(currentTimeAtInstanceTz) || false
    }
    return false
}
