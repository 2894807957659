import React, { useContext, useEffect, FunctionComponent, Fragment } from "react"
import { Redirect, useHistory } from "react-router"
import routes from "../routes"
import { AuthContext } from "../providers/AuthProvider"
import { AppContext } from "../providers/AppProvider"
import ClientStorage from "../utils/ClientStorage"

export enum ProtectedRouteType {
    AGENT,
    WORKER
}

interface Props {
    type: ProtectedRouteType
}

const ProtectedRoute: FunctionComponent<Props> = ({ children, type }) => {
    const { connectedUser, operationalModeConfig } = useContext(AuthContext)
    const { onNavigate } = useContext(AppContext)
    const history = useHistory()

    useEffect(() => {
        history.listen(() => {
            onNavigate()
        })
    }, [history, onNavigate])

    if (type === ProtectedRouteType.AGENT && connectedUser) {
        return <Fragment>{children}</Fragment>
    }

    if (type === ProtectedRouteType.WORKER && operationalModeConfig) {
        return <Fragment>{children}</Fragment>
    }

    return <Redirect to={ClientStorage.getItem('IsSsoLoginEnabled') ? routes.LOGIN_WITH_SSO : routes.LOGIN}></Redirect>
}

export default ProtectedRoute
