import { defaultMessages } from "./defaultMessages"
import { findMessage } from "../../utils/Tools"

const messages: { [key: string]: string } = {
    orgName: "Datum",
    welcome: "Log in to Datum",
    contactSupport: "Please contact a Representative and sign in another way.",
    qrInterfaceDetails: "Use QR codes created in Universe. Presenting the worker's QR code to the tablet's camera matches to the worker's profile. Attaching the device to main power is recommended.",
}

const datum = (key: string, attr: string[]): string => {
    return findMessage(key, messages, attr) || defaultMessages(key, attr)
}

export default datum
