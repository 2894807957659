import React, { useContext } from "react"
import moment from "moment"
import Shift from "interfaces/Shift"
import Worker from "interfaces/Worker"
import { AuthContext } from "providers/AuthProvider"

type Props = {
    shift: Shift, worker: Worker, isDone: boolean
}

export default function MessageBlock({ shift, worker, isDone}: Props) {
    const { Message } = useContext(AuthContext)

    const startIntroContent = (worker: Worker) => {
        const partOfDay = moment().hours() >= 12 ? Message("afternoon") : Message("morning")
        return (
            <>
                <p className="bigger">{Message("welcomeWorker", [partOfDay, worker.firstName])}</p>
                <p className="bigger">
                    {Message("shiftFound")}
                    <br />
                    {Message("shiftCheckStart")}
                </p>
            </>
        )
    }

    const justStartedContent = (worker: Worker) => (
        <>
            <p className="bigger">{Message("shiftStarted", [worker.firstName])}</p>
            <p className="bigger">{Message("rememberStopShift")}</p>
        </>
    )

    const stopIntroContent = (worker: Worker) => (
        <>
            <p className="bigger">
                {Message("helloAgain", [worker.firstName])} {Message("shiftStopNow")}
            </p>
        </>
    )

    const justStoppedContent = (worker: Worker) => (
        <p className="bigger">{Message("shiftStopCongrats", [worker.firstName])}</p>
    )

    if (shift.action === "START_SHIFT" && isDone) {
        return justStartedContent(worker)
    }
    
    if (shift.action === "STOP_SHIFT") {
        if (!isDone) {
            return stopIntroContent(worker)
        } else {
            return justStoppedContent(worker)
        }
    }

    return startIntroContent(worker)
}