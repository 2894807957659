import React, { useContext } from "react"
import TimerButton from "components/widgets/TimerButton"
import Shift from "interfaces/Shift"
import { AuthContext } from "providers/AuthProvider"
import ShiftInfo from "./ShiftInfo"

import "./ConfirmationNotice.scss"

type Props = {
    message: string
    shift: Shift
    timezonesEnabled: boolean
    onRestart: () => void
    autoRestartMs: number
}

export default function ConfirmationNotice({ message, shift, timezonesEnabled, onRestart, autoRestartMs }: Props) {
    const { Message } = useContext(AuthContext)

    return (
        <div className="confirmationNotice">
            <div className="banner">
                <div className="icon">
                    <svg viewBox="0 0 488 490" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M488 244.575C488 379.651 378.758 489.15 244 489.15C109.242 489.15 -3.90462e-08 379.651 -2.5154e-08 244.575C-1.12618e-08 109.499 109.242 -0.000488293 244 -0.000488306C378.758 -0.00048832 488 109.499 488 244.575ZM215.777 374.076L396.809 192.616C402.956 186.455 402.956 176.464 396.809 170.302L374.547 147.987C368.4 141.825 358.432 141.825 352.284 147.987L204.645 295.973L135.716 226.882C129.569 220.72 119.601 220.72 113.453 226.882L91.1911 249.196C85.0438 255.358 85.0438 265.349 91.1911 271.511L193.514 374.075C199.662 380.237 209.628 380.237 215.777 374.076Z"
                            fill="#468847"
                        />
                    </svg>
                </div>
                <div className="title">{message}</div>
            </div>
            <ShiftInfo shift={shift} timezonesEnabled={timezonesEnabled} />
            <div className="buttons">
                <TimerButton text={Message("done")} onClick={onRestart} autoClickInMs={autoRestartMs} />
            </div>
        </div>
    )
}
