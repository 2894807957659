import React, { FunctionComponent } from "react"

interface CountdownLightsProps {
    countdownStep: number
}

const CountdownLights: FunctionComponent<CountdownLightsProps> = ({ countdownStep }: CountdownLightsProps) => {
    const sequenceIndex = countdownStep >= 4 ? 4 : countdownStep
    return (
        <div className="countdownLightsHolder">
            {lightSequence[sequenceIndex].map((lightColour: LightColour, index: number) => {
                return <Light key={index} colour={lightColour} />
            })}
        </div>
    )
}

enum LightColour {
    OFF = 'rgba(0, 0, 0, 0.5)',
    RED = 'rgba(239, 100, 62, 0.8)',
    GREEN = 'rgba(43, 220 ,51, 0.8)'
}

//  array is in reverse temporal order since countdown decreases
const lightSequence = [
    [LightColour.GREEN, LightColour.GREEN, LightColour.GREEN],
    [LightColour.RED, LightColour.RED, LightColour.RED],
    [LightColour.RED, LightColour.RED, LightColour.OFF],
    [LightColour.RED, LightColour.OFF, LightColour.OFF],
    [LightColour.OFF, LightColour.OFF, LightColour.OFF]
]

interface LightProps {
    colour: LightColour
}

const Light: FunctionComponent<LightProps> = ({ colour }: LightProps) => {

    return (
        <div className="countdownLight" style={{ backgroundColor: colour }}/>
    )
}

export default CountdownLights
