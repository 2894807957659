import React, { FunctionComponent, useContext } from "react"
import { InteractionMode } from "../interfaces/InteractionMode"
import Countdown from "./Countdown"

import { AuthContext } from "../providers/AuthProvider"

import "./FaceNotFound.scss"
import TimerButton from "./widgets/TimerButton"
import ErrorMessageWithIcon from "./widgets/ErrorMessageWithIcon"

interface Props {
    onRestart: () => void
    interactionMode: InteractionMode | undefined
    idleRestartSeconds: number
    title: string
    message: string
}

const FaceNotFound: FunctionComponent<Props> = ({
    onRestart,
    interactionMode,
    idleRestartSeconds,
    title,
    message
}: Props) => {
    const { operationalModeConfig, Message } = useContext(AuthContext)
    const resetCountdown = operationalModeConfig?.resetCountdown || 5

    return (
        <div className="faceNotFound contentBox">
            <ErrorMessageWithIcon title={title} message={Message(message)} />
            <div className="bottomBar">
                {interactionMode !== InteractionMode.TOUCH ? (
                    <div className="contentBox textAlignCenter">
                        <Countdown
                            initialCountdownSeconds={resetCountdown}
                            displayedMessage="stepAside"
                            onCountdownComplete={onRestart}
                        />
                    </div>
                ) : (
                    <TimerButton
                        text={Message("startAgain")}
                        onClick={onRestart}
                        autoClickInMs={1000 * idleRestartSeconds}
                    />
                )}
            </div>
        </div>
    )
}

export default FaceNotFound
